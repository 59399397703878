<template>
  <div class="repairPending">

    <!--        导航-->
    <rxNavBar title="装修" androidOrIOSFlag="true"></rxNavBar>
    <!--        搜索-->
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="goSearch"></div>
        <input type="text" placeholder="房源地址/申请人姓名/手机号" v-model="searchContent" @keyup.enter="goSearch">
      </div>
      <div class="types">
        <div class="type" @click="showOrHidden" ref="downList">{{typeList[number] ? typeList[number].text : '装配中' }}</div>
        <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
      </div>
    </div>
    <div style="height: 2.13rem"></div>
    <van-pull-refresh v-model="isLoading"  @refresh="onRefresh">
      <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="noContent ? '' : '已经到底了'"
              @load="this.onLoad"
      >
        <div class="panel" v-for="(house,index) in resource" :key="index">
          <div class="house_num">
            <div class="public_status approval" v-if="house.afterRentStatusId == 0">待审批</div>
            <div class="public_status pending" v-if="house.afterRentStatusId == 1">待处理</div>
            <div class="public_status inDesign" v-if="house.afterRentStatusId == 2">设计中</div>
            <div class="public_status pendingApproval" v-if="house.afterRentStatusId == 3">装配中</div>
            <!--                    <div class="public_status overdue" v-if="house.afterRentStatusId == 3">逾期</div>-->
            <div class="public_status assemblyInProgress" v-if="house.afterRentStatusId == 4">待验收</div>
            <div class="public_status toBeSettled" v-if="house.afterRentStatusId == 5">待决算</div>
            <div class="public_status completed" v-if="house.afterRentStatusId == 6">已完成</div>
            <div class="public_status waitAccept" v-if="house.afterRentStatusId == 7">未交房</div>
            <div class="public_status cancel" v-if="house.afterRentStatusId == 8">已取消</div>
            <div class="public_status inDesigns" v-if="house.afterRentStatusId == 12">设计待审批</div>
            <div class="public_status assemblyInProgresss" v-if="house.afterRentStatusId == 13">验收待审批</div>
            <div class="public_status toBeSettleds" v-if="house.afterRentStatusId == 14">决算待审批</div>
            <div class="title bordBottom">
              <p>{{ house.roomDetailedAddress }}</p>
              <div class="relation" @click="checkMobileShow(house)"></div>
            </div>
            <div @click="pageJump(house)">
              <div class="panelHeader">
                <div class="panelInfo one"><label>申请人：<span>{{ house.applicationGlobaluserName }}</span></label></div>
                <div class="panelInfo">
                  <label>类型：
                    <span v-if="house.isFirst == 1">整装(首次)</span>
                    <span v-if="house.isFirst != 1">整装(二次)</span>
                  </label>
                </div>
              </div>
              <div>
                <div class="panelInfo one"><label>预算：<span>{{ house.maxBudgetFee }}元</span></label></div>
                <div class="panelInfo"><label>费用承担：<span
                        style="color: rgb(244, 99, 76);">{{ house.costBearStr }}</span></label>
                </div>
              </div>
              <div class="panelInfo float"><label>维修类型：<span>{{ house.dictionaryTitle }}</span></label></div>
              <div class="panelInfo float"><label>交房日期：<span
                      style="color: rgb(244, 99, 76);">{{ house.giveHouseTime }}</span></label>
              </div>
              <div class="panelInfo float"><p>装修需求：<span>{{ house.demand }}</span></p></div>
              <div class="panelInfo last bordBottom"><p>装修描述：<span>{{ house.afterRentDesprition }}</span></p></div>

            </div>
          </div>
          <div class="button_row" v-if="buttonShow(house) && roleType=='1'">
            <!--<div class="button_row">-->
            <div class="space"
                 v-if="house.afterRentStatusId=='0' || house.afterRentStatusId=='1' || house.afterRentStatusId=='2'">
              <img class="trashImg" src="..\..\..\assets\images\RepairPending-trashCan.png" v-if="checkAuthList(authButtonsList,'delete')" @click="dialog(house)">
              <van-button plain round size="small" class="moreBtn one" v-if="checkAuthList(authButtonsList,'cancelDecoration')" @click="checkCancel(house,index)">取消</van-button>
            </div>
            <div class="space" v-if="house.afterRentStatusId=='1' && checkAuthList(authButtonsList,'distributeLeaflets')">
              <van-button round size="small" class="moreBtn two" @click="sendSingler(house,index)">派单</van-button>
            </div>
            <!--          <div class="space" v-if="house.afterRentStatusId=='0' || checkAuthList(authButtonsList,'delete')">-->
            <!--&lt;!&ndash;            <van-button round size="small" class="moreBtn one">修改方案</van-button>&ndash;&gt;-->
            <!--            <img class="trashImg" src="..\..\..\assets\images\RepairPending-trashCan.png" v-if="checkAuthList(authButtonsList,'delete')" @click="dialog(house)">-->
            <!--          </div>-->
            <div class="space" v-if="((house.afterRentStatusId=='2' || house.afterRentStatusId=='3')&&typeList[number].value!='6') && checkAuthList(authButtonsList,'assemblyPlan')">
              <van-button @click="decoratePlan(house)" round size="small" class="moreBtn two">装配方案</van-button>
            </div>
            <div class="space"
                 v-if="(house.afterRentStatusId=='2' || house.afterRentStatusId=='3' || house.afterRentStatusId=='4') && checkAuthList(authButtonsList,'reassignment')">
              <van-button round size="small" class="moreBtn three" @click="sendSingler(house,index)">改派</van-button>
            </div>
            <div class="space" v-if="house.afterRentStatusId=='3' && checkAuthList(authButtonsList,'updateProgress')">
              <van-button round size="small" class="moreBtn two" @click="updateProgress(house)">更新进度</van-button>
            </div>
            <div class="space" v-if="house.afterRentStatusId=='-1'">
              <van-button round size="small" class="moreBtn one">查看方案</van-button>
            </div>
            <div class="space" v-if="house.afterRentStatusId=='4' && checkAuthList(authButtonsList,'specialCheckAcceptance')">
              <van-button round size="small" class="moreBtn two" @click="doceration(house)">验收</van-button>
            </div>
            <div class="space" v-if="house.afterRentStatusId=='-1'">
              <van-button round size="small" class="moreBtn two">决算</van-button>
            </div>
            <div class="space">
              <div class="getPwdDiv" v-if="house.afterRentStatusId=='3'&&house.publicDeviceId">
                <img class="getPwd" src="..\..\..\assets\images\RepairPending-getPwd.png" alt="">
                <div class="getPwdTextDiv"><span class="getPwdText"></span></div>
              </div>
            </div>
          </div>

          <!--只有一个更新进度按钮，用于装修负责人使用-->
          <div class="button_row" v-if="roleType=='0' && house.afterRentStatusId=='3'">
            <div class="space">
              <van-button round size="small" class="moreBtn two" @click="updateProgress(house)">更新进度</van-button>
            </div>
          </div>


        </div>
      </van-list>
      <common-empty v-if="noContent"></common-empty>
    </van-pull-refresh>
    <!--派单的弹窗-->
    <van-popup v-if="resource[index]"
               class="van-popup--bottom"
               v-model="resource[index].sendShow"
               position="bottom"
               @click-overlay="resource[index].chargeGlobaluser = chargePseson.chargeGlobaluser;resource[index].chargePerson = chargePseson.chargePerson;chargePsesonIndex = chargePseson.chargePsesonIndex;
                               resource[index].costBear = theExpense.costBear;resource[index].costBearStr = theExpense.costBearStr;expenseIndex = theExpense.expenseIndex;
                               resource[index].designer = designer.designer;resource[index].designGlobaluser = designer.designGlobaluser;designerIndex = designer.designerIndex;
                               "
               style="backgroundColor: #ffffff ">
      <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;" src="../../../assets/images/colon.png">
      <div style="margin-top: 18px;margin-bottom: 20px;font-size: 15px;font-weight: bold">装修派单</div>
      <div class="part-inputpart">
        <!--负责人-->
        <div ref="chargePseson" class="part-inputpart-row" @click="clickChargePsesonShow">
          <span :class="resource[index].chargeGlobaluser? 'content-have' : 'content-none'"></span>
          <span class="part-inputpart-row-header">负责人</span>
          <span class="content-divide">|</span>
          <span class="part-inputpart-row-graytext"
                :class="resource[index].chargeGlobaluser ? 'part-inputpart-row-normaltext':'' ">{{ resource[index].chargeGlobaluser }}</span>
          <img class="part-inputpart-row-right"
               :class=" isChargePersonSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
               src="../../../assets/images/triangle.png">
        </div>
        <!--可选择负责人选项-->
<!--        <div>-->
<!--          <div class="part-inputpart part-inputpart-dropDownList" v-if="isChargePersonSelect">-->
<!--            <div class="part-inputpart-dropDownList-option"-->
<!--                 :class="item.userName==resource[index].chargeGlobaluser ? 'part-inputpart-dropDownList-option-selected' : '' "-->
<!--                 v-for="(item,i) in chargePsesonArr" :key="i" @click="selectChargePsesonOption(item)">-->
<!--              {{ item.userName }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <van-popup v-model="isChargePersonSelect" position="bottom">
          <van-picker
              show-toolbar
              :columns="chargePsesonArr"
              @cancel="isChargePersonSelect = false"
              :default-index="chargePsesonIndex"
              value-key="userName"
              @confirm="selectChargePsesonOption"
          />
        </van-popup>
        <!--费用承担方-->
        <div ref="theExpense" class="part-inputpart-row" @click="clickExpenseShow">
          <span :class="resource[index].costBearStr? 'content-have' : 'content-none'"></span>
          <span class="part-inputpart-row-header">费用承担方</span>
          <span class="content-divide">|</span>
          <span class="part-inputpart-row-graytext"
                :class="resource[index].costBearStr ? 'part-inputpart-row-normaltext':'' ">{{ resource[index].costBearStr }}</span>
          <img class="part-inputpart-row-right"
               :class=" isExpenseSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
               src="../../../assets/images/triangle.png">
        </div>
        <!--费用承担方选项-->
<!--        <div>-->
<!--          <div class="part-inputpart part-inputpart-dropDownList" v-if="isExpenseSelect">-->
<!--            <div class="part-inputpart-dropDownList-option"-->
<!--                 :class="item.dictionaryTitle==resource[index].costBearStr ? 'part-inputpart-dropDownList-option-selected' : '' "-->
<!--                 v-for="(item,i) in expenseArr" :key="i" @click="selectExpenseOption(item)">{{ item.dictionaryTitle }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <van-popup v-model="isExpenseSelect" position="bottom">
          <van-picker
              show-toolbar
              :columns="expenseArr"
              @cancel="isExpenseSelect = false"
              :default-index="expenseIndex"
              value-key="dictionaryTitle"
              @confirm="selectExpenseOption"
          />
        </van-popup>
        <!--设计师-->
        <div ref="designer" class="part-inputpart-row" @click="clickDesignerShow">
          <span :class="resource[index].designGlobaluser? 'content-have' : 'content-none'"></span>
          <span class="part-inputpart-row-header">设计师</span>
          <span class="content-divide">|</span>
          <span class="part-inputpart-row-graytext"
                :class="resource[index].designGlobaluser ? 'part-inputpart-row-normaltext':'' ">{{ resource[index].designGlobaluser }}</span>
          <img class="part-inputpart-row-right"
               :class=" isDesignerSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
               src="../../../assets/images/triangle.png">
        </div>
        <!--设计师选项-->
        <div>
          <div class="part-inputpart part-inputpart-dropDownList" v-if="isDesignerSelect">
            <div class="part-inputpart-dropDownList-option"
                 :class="item.userName==resource[index].designGlobaluser ? 'part-inputpart-dropDownList-option-selected' : '' "
                 v-for="(item,i) in designerArr" :key="i" @click="selectDesignerOption(item)">{{ item.userName }}
            </div>
          </div>
        </div>
        <van-popup v-model="isDesignerSelect" position="bottom">
          <van-picker
              show-toolbar
              :columns="designerArr"
              @cancel="isDesignerSelect = false"
              :default-index="designerIndex"
              value-key="userName"
              @confirm="selectDesignerOption"
          />
        </van-popup>
      </div>
      <van-button @click="confirmSendSingler"
                  :class="resource[index].chargeGlobaluser && resource[index].costBearStr && resource[index].designGlobaluser? 'saveButton_Enable' : 'saveButton_Disable' "
                  :disabled="!(resource[index].chargeGlobaluser && resource[index].costBearStr && resource[index].designGlobaluser) || enable"
      >确定
      </van-button>
    </van-popup>
    <!--取消的弹窗-->
    <van-popup v-if="resource[index]" class="van-popup--bottom" v-model="resource[index].cancelShow" position="bottom"
               style="backgroundColor:#ffffff">
      <div>
        <img class="cancel-img" src="../../../assets/images/quotationmarks.png">
        <span class="weight">确认</span><br>
        <span class="weight" style="margin-left: 38px">取消该装修申请？</span>
      </div>
      <textarea class="cancel-textarea" v-model="resource[index].cancelReason" placeholder="输入取消原因"></textarea>
      <van-button @click="confirmCancel"
                  :class=" 0 != resource[index].cancelReason.trim().length ? 'saveButton_Enable' : 'saveButton_Disable' "
                  :disabled="0 == resource[index].cancelReason.trim().length || enable"
      >确定
      </van-button>
    </van-popup>
    <van-popup v-model="isShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="typeList"
          @cancel="isShow = false"
          :default-index="number"
          value-key="text"
          @confirm="changeType"
      />
    </van-popup>
    <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle"
                 :mobileShow="isMobileShow" @hideDialog="isMobileShow = false">
    </dial-mobile>

  </div>
</template>
<script>
import {
  NavBar,
  Tab,
  Tabs,
  Search,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Overlay,
  Picker,
  Popup,
  Field,
  List,
  PullRefresh,
  Dialog
} from 'vant'
import {
  afterRentDecorationList, cancelDecoration, delDecoration,
  queryBaseData,
  returnChargeAndDesignerList, saveDecorationList, saveReformSchool,
  showReformSchool,getStaffFuntionModelList
} from "../../../getData/getData"
import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil"
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar"
import {checkAuthList} from "../../../libs/rongxunUtil";

export default {
  data() {
    return {
      roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
      isLoading: '',
      chargePsesonIndex: '',
      expenseIndex: '',
      designerIndex: '',
      authButtonsList: [],
      index: '',
      temporaryOwnerMobile: '',
      temporaryOwnerName: '',
      dialogTitle: '联系负责人',
      isMobileShow: false,
      noContent: false,
      loading: false,
      finished: false,
      //装修类型
      number: 4,
      //当前页
      page: 1,
      //数据总条数
      count: '',
      isShow: false,
      prvPopup: false,
      maskShow: false,
      searchContent: '',
      user: 0,
      approvalStatus: 0,
      type: 0,
      typeList: [
        {text: '全部', value: ''},
        {text: '待审批', value: 2},
        {text: '待处理', value: 3},
        {text: '设计中', value: 5},
        {text: '装配中', value: 6},
        {text: '逾期', value: 7},
        {text: '待验收', value: 8},
        {text: '待决算', value: 9},
        {text: '已完成', value: 10},
        {text: '未交房', value: 11},
        {text: '已取消', value: 12}
      ],
      //sendShow:false,//派单状态
      //装修负责人
      isChargePersonSelect: false,
      chargePseson: {},
      chargePsesonArr: [],//负责人列表

      //费用承担方
      isExpenseSelect: false,
      theExpense: {},
      expenseArr: [],//费用承担方

      //设计师
      isDesignerSelectAlraedy: false,
      isDesignerSelect: false,
      designer: {},
      designerArr: [],//设计师

      //approvalStatus:申请状态，
      // maxBudgetFee：预算
      //renovationType:装修类型
      //roomDetailedAddress:房源地址
      //staffName 员工姓名

      resource: [],

      setHouse_id: '',
      rtid: '',
      //安卓跳转
      //currentLabel: this.$route.query.currentLabel || ''
      updateList:[],    //装配方案权限
      enable:false
    }
  },
  name: 'repairPending',
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [List.name]: List,
    rxNavBar,
    dialMobile,
    [Picker.name]: Picker,
    [PullRefresh.name]: PullRefresh,
    [Dialog.Component.name]: Dialog.Component
  },

  mounted() {

  },
  destroyed() {
    document.removeEventListener('click',this.chargePsesonClick)
    document.removeEventListener('click',this.theExpenseClick)
    document.removeEventListener('click',this.designerClick)
    document.removeEventListener('click',this.downListClick)
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('type');
      localStorage.removeItem('inputValue');
      localStorage.removeItem('currentLabelPublic');
    }
    next()
  },

  created: function () {


    //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
    if(this.$route.query.roleType){
      this.roleType = this.$route.query.roleType;
    }else{
      //负责人，设计师，费用方列表初始化
      this.initChargeAndDesignerList()
      //按钮权限
      this.getStaffFuntionModelList()
      document.addEventListener('click',this.chargePsesonClick)
      document.addEventListener('click',this.theExpenseClick)
      document.addEventListener('click',this.designerClick)
      document.addEventListener('click',this.downListClick)
    }
    if(this.$route.query.currentLabel)
      for (let i in this.typeList)
        if(this.$route.query.currentLabel == this.typeList[i].value){
          this.number = Number(i)
          //localStorage.setItem('currentLabel',JSON.stringify(this.number))
        }
    //先读取local里存储的历史记录
    if('0' === localStorage.getItem('currentLabel') || localStorage.getItem('currentLabel'))
      this.number = Number(localStorage.getItem('currentLabel'));
    if(!this.number&&0!==this.number)
      this.number = 4


  },

  methods: {

    buttonShow(house) {
      if ((house.afterRentStatusId=='0' && (this.checkAuthList(this.authButtonsList,'delete') || this.checkAuthList(this.authButtonsList,'cancelDecoration') )) ||
          (house.afterRentStatusId=='1' && (this.checkAuthList(this.authButtonsList,'delete') || this.checkAuthList(this.authButtonsList,'cancelDecoration') || this.checkAuthList(this.authButtonsList,'distributeLeaflets') )) ||
          (house.afterRentStatusId=='2' && (this.checkAuthList(this.authButtonsList,'delete') || this.checkAuthList(this.authButtonsList,'cancelDecoration') || this.checkAuthList(this.authButtonsList,'assemblyPlan') || this.checkAuthList(this.authButtonsList,'reassignment') )) ||
          (house.afterRentStatusId=='3' && (this.checkAuthList(this.authButtonsList,'assemblyPlan') || this.checkAuthList(this.authButtonsList,'updateProgress') || this.checkAuthList(this.authButtonsList,'reassignment') )) ||
          (house.afterRentStatusId=='4' && (this.checkAuthList(this.authButtonsList,'reassignment') || this.checkAuthList(this.authButtonsList,'specialCheckAcceptance') )))
        return true
      else
        return false
    },

    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'afterRentDecoration_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
                  for(var i in that.authButtonsList){
                      if(that.authButtonsList[i]=="renovationchange"){
                          that.updateList.push('renovationchange') 
                       }else if(that.authButtonsList[i]=="renovationdel"){
                          that.updateList.push('renovationdel') 
                       }else if(that.authButtonsList[i]=="renovationfinish"){
                          that.updateList.push('renovationfinish') 
                       }else if(that.authButtonsList[i]== "renovationreceive"){
                          that.updateList.push('renovationreceive') 
                       }
                  }          
        })
      })
    },
    checkAuthList,

    checkMobileShow(house) {
      if (house.chargeGlobaluser) {
        this.isMobileShow = true
        this.temporaryOwnerMobile = house.mobile
        this.temporaryOwnerName = house.chargeGlobaluser
      } else
        responseUtil.alertMsg(this, '未填写装配方案')

    },

    dialog(house) {
      Dialog.confirm({
        title: '友情提示',
        message: '确定删除吗？',
      })
          .then(() => {
            // on confirm
            var that = this
            that.enable = true
            let initData = {}
            initData.user_id = globaluserId()
            initData.rtid = house.rtid
            delDecoration(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                if(200 == response.status){
                  for (let i = 0; i < that.resource.length; i++)
                    if (that.resource[i].rtid == house.rtid)
                      that.resource.splice(i, 1)
                  responseUtil.alertMsg(that,'删除成功')
                  that.enable=false
                }
              })
            })

          })
          .catch(() => {
            // on cancel
          });
    },

    checkCancel(house, index) {
      house.cancelShow = true
      this.index = index
    },

    confirmCancel() {
      this.enable = true
      let that = this
      let initData = {}
      initData.user_id = globaluserId()
      initData.rtid = that.resource[that.index].rtid
      initData.cancelReason = that.resource[that.index].cancelReason
      cancelDecoration(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response)
          if(200 == response.status){
            for (let i = 0; i < that.resource.length; i++)
              if (i == that.index)
                that.resource.splice(i, 1)
            responseUtil.alertMsg(that,'取消成功')
            that.enable = false
          }
        })
      })
    },

    goSearch() {
      this.resource = [];
      this.page = 1;
      this.finished = false
      this.loading = true
      this.noContent = false
      this.onLoad();
    },

    onRefresh() {
      this.resource = [];
        this.page = 1;
        this.finished = false
        this.loading = true
        this.noContent = false
        this.isLoading = false
      this.isLoading = false;
        this.onLoad();

        responseUtil.alertMsg(this,"刷新成功")
    },

    changeType(value, index) {
      if(value){
        localStorage.setItem('currentLabel',JSON.stringify(index))
        this.number = index
      }
      this.resource = [];
      this.page = 1;
      this.finished = false;
      this.isShow = false;
      this.loading = true;
      this.noContent = false;
      this.onLoad();
    },

    //显示隐藏下拉菜单，监听div点击事件，实现点击空白处隐藏下拉列表
    showOrHidden: function () {
      this.isShow = !this.isShow;
      // var sp = document.getElementById("downList");
      // if (sp) {
      //   document.addEventListener('click', e => {
      //     if (!sp.contains(e.target)) {
      //       this.isShow = false;
      //     }
      //   })
      // }
    },

    downListClick(e) {
      if(this.$refs.downList){
        if(this.$refs.downList.contains(e.target))
          return
        else
          this.isShow = false
      }
    },

    pageJump(house) {
      this.$router.push({
        name: 'fitmentAuditViewDetail',
        query: {
          //套房源id
          setHouse_id: house.setHouse_id,
          //装修id
          rtid: house.rtid,
          arid: house.id,
          afterRentStatusId:house.afterRentStatusId,
          fitmentProgramLimit:this.checkAuthList(this.authButtonsList,'assemblyPlan')    //装修方案权限
        }
      })
    },

    decoratePlan(house) {
      console.log(house)
      this.$router.push({
        name: 'decoratePlan',
        query: {
          //套房源id
          setHouse_id: house.setHouse_id,
          //装修id
          rtid: house.rtid,
          arid: house.id,
          contractId:house.contractId,
          contractStatusVal:house.contractStatusVal,
          contractStatus:house.contractStatus,
          ownerContractId:house.ownerContractId,
          isFirst:house.isFirst
        }
      })
    },

    updateProgress(house) {
      console.log(house)
      this.$router.push({
        name: 'updateProgress',
        query: {
          //套房源id
          setHouse_id: house.setHouse_id,
          //装修id
          rtid: house.rtid,
          arid: house.id,
          //是否是装修负责人  0 是    1 否
          roleType:this.roleType,
          updateList:this.updateList
        }
      })
    },
    doceration(house) {
      console.log(house)
      this.$router.push({
        name: 'doceration',
        query: {
          //套房源id
          setHouse_id: house.setHouse_id,
          //装修id
          rtid: house.rtid,
          arid: house.id,
        }
      })
    },

    //下拉加载
    onLoad: function () {
      var that = this
      let initData = {}
      //如果是装修负责人则获取员工id
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else{
        initData.user_id = globaluserId()
      }
      //initData.staff_id = getStaffId()
      //租后类型 装修
      initData.afterRentType = '0'
      //下拉刷新的页数
      initData.currentPage = that.page
      initData.numberPage = 3
      initData.roleType = that.roleType
      initData.currentLabel = that.typeList[that.number].value

      if (that.searchContent)
        initData.searchContent = that.searchContent

      afterRentDecorationList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data.data.data)
          that.count = response.data.data.data.pageCount
          let list = response.data.data.data.data
          if (list)
            for (let i = 0; i < list.length; i++) {
              list[i].sendShow = false
              list[i].cancelShow = false
              list[i].cancelReason = ''
              that.resource.push(list[i])
            }
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          if (!list || !list.length || that.resource.length >= that.count) {
            that.finished = true;
            //console.log(that.finished)
          }
          if (1 == that.page && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
            that.page++;
          }
        })
      })
    },
    //负责人，设计师，费用方列表初始化
    initChargeAndDesignerList: function () {
      var that = this
      let initData = {}
      //initData.user_id= globaluserId()
      initData.userRoleList = ["decorationWorker", "designer", "chargePerson"]
      returnChargeAndDesignerList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let data = response.data.data
          //负责人
          that.chargePsesonArr = data.chargePerson
          //that.expenseArr = data.decorationWorker
          //设计师
          that.designerArr = data.designer
        })
      })
      initData.fdName = ['COSTBEARMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //费用承担方
          that.expenseArr = response.data.data.COSTBEARMAP
        })
      })
    },
    showPopupWithPrv() {
      this.maskShow = true
      this.prvPopup = true
    },
    showPopupWithInvalid(e) {
      this.isvoide = e
      this.maskShow = true
      this.prvPopup = false
    },

    //点击负责人下拉框区域外 下拉框隐藏
    clickChargePsesonShow() {
      this.isChargePersonSelect = !this.isChargePersonSelect;
      // var panel = document.getElementById('chargePseson')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     console.log(this.isChargePersonSelect+'负责人下拉框')
      //     if (!panel.contains(e.target)) {
      //       this.isChargePersonSelect = false
      //     }
      //   })
      // }
    },
    chargePsesonClick(e) {
      if(this.$refs.chargePseson){
        if(this.$refs.chargePseson.contains(e.target))
          return
        else
          this.isChargePersonSelect = false
      }
    },

    //点击费用承担方下拉框区域外 下拉框隐藏
    clickExpenseShow() {
      this.isExpenseSelect = !this.isExpenseSelect;
      // var panel = document.getElementById('theExpense')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     console.log(this.isExpenseSelect+'费用承担方下拉框')
      //     if (!panel.contains(e.target)) {
      //       this.isExpenseSelect = false
      //     }
      //   })
      // }
    },
    theExpenseClick(e) {
      if(this.$refs.theExpense){
        if(this.$refs.theExpense.contains(e.target))
          return
        else
          this.isExpenseSelect = false
      }
    },

    //点击设计师下拉框区域外 下拉框隐藏
    clickDesignerShow() {
      this.isDesignerSelect = !this.isDesignerSelect;
      // var panel = document.getElementById('designer')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     console.log(this.isDesignerSelect+'设计师下拉框')
      //     if (!panel.contains(e.target)) {
      //       this.isDesignerSelect = false
      //     }
      //   })
      // }
    },
    designerClick(e) {
      if(this.$refs.designer){
        if(this.$refs.designer.contains(e.target))
          return
        else
          this.isDesignerSelect = false
      }
    },

    //点击负责人下拉框绑定属性
    selectChargePsesonOption(item,index) {
      if(item){
        this.resource[this.index].chargePerson = item.id
        this.resource[this.index].chargeGlobaluser = item.userName
        this.chargePsesonIndex = index
      }
      this.isChargePersonSelect = false
    },

    //点击费用承担方下拉框绑定属性
    selectExpenseOption(item,index) {
      if(item){
        this.resource[this.index].costBear = item.dictionaryValue
        this.resource[this.index].costBearStr = item.dictionaryTitle
        this.expenseIndex = index
      }
      this.isExpenseSelect = false
    },

    //点击设计师下拉框绑定属性
    selectDesignerOption(item,index) {
      if(item){
        this.resource[this.index].designer = item.id
        this.resource[this.index].designGlobaluser = item.userName
        this.designerIndex = index
      }
      this.isDesignerSelect = false
    },

    //派单点击事件
    sendSingler(house, index) {
      // var that = this
      // let initData = {}
      // initData.user_id = globaluserId()
      // initData.rtid = house.rtid
      // showReformSchool(initData).then(function (response) {
      //     responseUtil.dealResponse(that, response, () => {
      //         console.log(response.data.data)
      //     })
      // })
      //负责人
      this.$set(this.chargePseson,'chargePerson',house.chargePerson || '')
      this.$set(this.chargePseson,'chargeGlobaluser',house.chargeGlobaluser || '')
      this.$set(this.chargePseson,'chargePsesonIndex',this.chargePsesonIndex || '')
      //费用承担方
      this.$set(this.theExpense,'costBear',house.costBear || '')
      this.$set(this.theExpense,'costBearStr',house.costBearStr || '')
      this.$set(this.theExpense,'expenseIndex',this.expenseIndex || '')
      //设计师
      this.$set(this.designer,'designer',house.designer || '')
      this.$set(this.designer,'designGlobaluser',house.designGlobaluser || '')
      this.$set(this.designer,'designerIndex',this.designerIndex || '')
      house.sendShow = true
      this.index = index
    },
    //派单弹框确定按钮
    confirmSendSingler() {
      this.enable=true
      var that = this
      let initData = {}
      initData.user_id = globaluserId()
      initData.rtid = that.resource[that.index].rtid
      initData.costBear = that.resource[that.index].costBear
      initData.chargePerson = that.resource[that.index].chargePerson
      initData.designer = that.resource[that.index].designer
	  initData.contractId = that.resource[that.index].contractId //合同id
	  
      if ('1' == that.resource[that.index].afterRentStatusId) {
        saveDecorationList(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            if (200 == response.status) {
              // for (let i = 0; i < that.resource.length; i++)
              //   if (i == that.index)
              //     that.resource.splice(i, 1)
              responseUtil.alertMsg(that, '操作成功')
              that.enable=false
              that.goSearch()
            }
          })
        })
      } else {
        saveReformSchool(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            if (200 == response.status)
              responseUtil.alertMsg(that, '操作成功')
              that.goSearch()
          })
        })
      }
      that.resource[that.index].sendShow = false
    },
  }
}
</script>
<style lang="less" scoped>

.cancel-img {
  margin: 30px 5px -2px 15px;
  width: 18px;
  height: 16px;
}

.cancel-textarea {
  margin: 15px 15px 0;
  width: 325px;
  padding: 10px;
  height: 80px;
  border-radius: 8px;
  border: none;
  background-color: #FaFaFa;
  resize: none;
}

.cancel-textarea::-webkit-input-placeholder {
  color: #d8d8d8;
}

.weight {
  font-weight: 900;
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}

.search {
  margin: 8px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}

.types {
  width: 100%;
  height: 47px;
  display: flex;
}

.type {
  width: auto;
  height: 17px;
  line-height: 17px;
  text-align: left;
  color: #fe5e3a;
  margin: 15px 0 0 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 20px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin: 18px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.choose-select {
  position: fixed;
  z-index: 6;
  padding-top: 90px;
  width: 100%;
}

.selectList {
  width: 100%;
  height: auto;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

@radius: 8px;
@border: 1px solid rgb(244, 244, 244);
/*添加的派单弹窗样式start*/

/*弹窗样式*/
.van-popup--bottom {
  /*z-index: 2004;*/
  background-color: #f8f8f8;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

/*选择名称的div*/
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin: 15px 15px 0 15px;
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 14px;
  width: 150px;
}

/*竖线样式*/
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 5px 0 5px;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 14px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
  font-size: 14px;
  color: #D8D8D8;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 92.5%;
  position: absolute;
  z-index: 99;
}

.part-inputpart-row-normaltext {
  color: black;
}

/*选择三角图标的向上向下样式*/
.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin-left: 15px;
  width: 345px;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

/*保存按钮不可点击样式*/
.saveButton_Disable {
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 50px;
  border-radius: 8px;
  margin: 30px 15px 30px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  margin: 30px 15px 30px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

/*添加的派单弹窗样式end*/

.repairPending {
  width: 100%;
  background-color: rgb(250, 250, 250);
  font-size: 12px;

  p {
    margin: 0
  }

  .line {
    font-size: 20px;
  }

  .bordBottom {
    border-bottom: @border;
  }

  .panel {
    width: 345px;
    margin: 15px auto;
    background-color: rgb(255, 255, 255);
    border-radius: @radius;
    position: relative;
    font-size: 15px;
    text-align: left;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .house_num {
      margin: 0;

      .title {
        padding: 20px 14px;
        height: 35px;
        line-height: 20px;
        font-weight: bolder;
        text-align: left;
        display: flex;
        justify-content: space-between;
        width: 315px;
        border-bottom: @border;
        font-size: 15px;
        position: relative;

        p {
          padding: 0;
          margin: 0;
          font-size: 14px;
          font-weight: bolder;
          width: 80%;
        }

        .relation {
          width: 20px;
          height: 20px;
          background-image: url(../../../assets/images/relation-phone.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    .public_status {
      position: absolute;
      left: 5px;
      top: 0px;
      padding: 1px 7px;
      height: 32px;
      line-height: 32px;
      background-color: rgb(47, 210, 172);
      border-radius: 16px 0px;
      font-size: 19px;
      color: rgb(255, 255, 255);
      font-weight: bold;
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;

      &.approval {
        background: linear-gradient(to right, #cc004b, #660026);
      }

      &.pending {
        background: linear-gradient(to right, #FFBC6F, #FE683E);
      }

      &.inDesign {
        background: linear-gradient(to right, #77C3FE, #3997FF);
      }

      &.inDesigns {
        background: linear-gradient(to right, #3997FF, #003d80);
      }

      &.pendingApproval {
        background: linear-gradient(to right, #00cc00, #008000);
      }

      &.overdue {
        background: linear-gradient(to right, #F5346B, #FB6869);
      }

      &.assemblyInProgress {
        background: linear-gradient(to right, #63CB93, #2CD3B0);
      }

      &.assemblyInProgresss {
        background: linear-gradient(to right, #2CD3B0, #166958);
      }

      &.toBeSettled {
        background: linear-gradient(to right, #05C7CA, #00B0B6);
      }

      &.toBeSettleds {
        background: linear-gradient(to right, #00B0B6, #006366);
      }

      &.completed {
        background: linear-gradient(to right, #bfcbd9, #a8b9cc);
      }

      &.waitAccept {
        background: linear-gradient(to right, #d7ff99, #adff2f);
      }

      &.cancel {
        background: linear-gradient(to right, #929292, #999999);
      }
    }

    .panelHeader {
      padding-top: 14px;
    }

    .panelInfo {
      padding: 0 6px 6px 14px;
      font-size: 14px;

      &.one {
        width: 42.5%;
        float: left;
      }

      &.float {
        padding: 0px 6px 6px 14px;

        p {
          font-weight: bolder;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      &.last {
        padding: 0px 6px 6px 14px;

        p {
          margin: 0 0 6px 0;
          font-weight: bolder;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      label {
        font-weight: bolder;

        .fitment {
          color: #f00;
        }

        span {
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }

    }

    .button_row {
      position: relative;
      padding: 14px;
      border-bottom: @border;
      display: flex;
      justify-content: flex-end;

      .delete {
        width: 20px;
        height: 20px;
        background-image: url(../../../assets/images/relation-phone.png);
        background-repeat: no-repeat;
        background-size: cover;
      }

      .space {
        padding-left: 8px;
      }

      .getPwdDiv {
        display: flex;
        position: absolute;
        left: 15px;
        top: 17px;
        font-size: 13px;
        height: 25px;
        line-height: 30px;
      }

      .getPwdTextDiv {
        /*margin-bottom: -10px;*/
      }

      .getPwdText {
        font-size: 13px;
        color: #999999;
      }

      /*    垃圾桶的图标*/

      .trashImg {
        position: absolute;
        left: 15px;
        top: 17px;
        width: 30px;
        height: 25px;
      }

      .getPwd {
        width: 32px;
        height: 25px;
      }

      .moreBtn {
        width: 80px;
        background-clip: padding-box, border-box;
        background-origin: border-box;
        border-radius: 8px;

        &.one {
          color: rgba(255, 93, 59, 1);
          background-color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(white, white), /* 底色，即原有的背景 */ linear-gradient(to right, #f5c998, #fe5e3e); /* 模拟渐变边框 */
        }

        &.two {
          color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(to right, rgb(255, 190, 110), rgb(255, 100, 60)); /* 模拟渐变边框 */
        }

        &.three {
          color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(to right, rgb(124, 198, 255), rgb(54, 147, 255)); /* 模拟渐变边框 */
        }
      }

    }
    //.button_row:empty{
    //  display: none;
    //}
  }

  .popup_wrapper {
    width: 48%;
    padding: 15px 0 30px;
    background-color: rgb(250, 250, 250);

    .drop_menu_wrapper {
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      background-color: rgb(255, 255, 255);
      border-radius: @radius;

      .right_menu {
        width: 100%;
        margin-left: 5px;
      }
    }
  }

}
</style>
